@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en");
/* Variables for colors */
:root {
  --main-color: #012638;
  --background-color: #fff;
  --reverse-background: #f3f3f4;
  --border-color: #e7eaec;
  --tab-link-color: #a7b1c2;
  --circle-fill: #d6d6d6;
  --label-color: #012638;
  --account-circle: #F5A623;
  --default-circle: #50E3C2;
  --font-color: #000;
  --boxed-color: #2f4050;
  --nav-hover: #e6e6e6;
  --dark: #262626;
  --delete-color: #F05757;
}

body { overflow: visible !important; }

body {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #fff;
  font-size: 13px !important;
  color: #000;
  overflow-x: hidden;
}

body {
  background: #ffffff;
  display: flex;
  flex-flow: column;
}

footer {
  margin-top: auto;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 25px; /* Vertically center the text there */
}

footer a, .alert a, .link {
  color: #012638 !important;
}

.navbar-toggler {
  color: #fff !important;
}

.navbar-toggler {
  color: #fff !important;
}

.nav-header {
  padding: 10px 20px 10px 25px;
  background-color: #012638;
  color: #fff;
}

.vertical-nav .nav-tabs .nav-link {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  color: 'black' !important;
}

.nav-link {
  color: #fff;
}

.nav-link:hover {
  color: #a6cc38;
}

.vertical-nav .tab-content {
  width: 80%;
}

.vertical-nav .nav-link.active {
  border-color: transparent !important;
  background-color: #DCDCDC !important;
}

.navbar-fixed-top {
  height: 60px;
  /*background-color: $nav;*/
  background-color: #fff;
}

.sub-nav {
  background-color: #012638 !important;
}

.nav-brand {
  color: #fff !important;
}

.new-text {
  color: #a4cd27;
}

.green-alert {
  background-color: #012638 !important;
  color: #fff !important;
}

.white-alert {
  background-color: #fff !important;
  color: red !important;
  border-color: #012638 !important;
}

.warning-text {
  color: yellow;
}

.danger-text {
  color: #F05757;
  white-space: pre;
}

.button {
  background-color: #012638 !important;
  border-color: #012638 !important;
  color: #fff !important;
  cursor: pointer;
}

.button:hover, button:hover {
  background-color: #a6cc38 !important;
  color: #012638 !important;
  border-color: #a6cc38 !important;
}

.delete-button {
  background-color: #fff !important;
  color: #F05757 !important;
  border-color: #F05757 !important;
}

.delete-button:hover {
  background-color: #F05757 !important;
  color: #fff !important;
  border: #fff !important;
}

.reverse-button {
  background-color: #fff !important;
  color: #012638 !important;
  border-color: #012638 !important;
}

.reverse-button:hover {
  background-color: #012638 !important;
  color: #fff !important;
  border: #fff !important;
}

.reverse-background {
  background-color: #f3f3f4;
}

.toastr {
  white-space: pre !important;
}

.redux-toastr .top-right {
  width: auto !important;
  min-width: 300px !important;
}

.loader {
 margin-top: 10%;
 margin-bottom: 100px;
}

.loader div, .loader h2 {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.button {
  background-color: #012638;
  color: #fff;
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 5 !important;
}

.react-datepicker-wrapper, .react-datepicker__input-container, .date-picker {
  width: 100%;
}

.border_top {
  border-top: 1px solid #e7eaec;
  padding: 8px;
  line-height: 1.42857;
}

span {
  font-size: 13px;
}

.search-bar .col-md-3, .search-bar .col-md-1 {
  display: inline-block;
}

.search-bar .col-md-3 .form-group, .search-bar .col-md-1 .form-group {
  display: inline;
}

/* ========================== */
/* ======= TYPOGRAPHY ======= */

h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 10px;
}

h3, h4, h5 {
  margin-top: 5px;
  font-weight: 600;
}

/* ======= TYPOGRAPHY ======= */
/* ========================== */


/* ========================== */
/* ========== BASE ========== */

html, body {
  height: 100%;
  position: relative;
  min-height: 100%;
}

.size-18 {
  font-size: 18px !important;
}

body.full-height-layout #wrapper,
body.full-height-layout #page-wrapper {
  height: 100%;
}

#page-wrapper {
  min-height: auto;
}

body.boxed-layout {
  background: $boxed-background;
}

body.boxed-layout #wrapper {
  background-color: #2f4050;;
  max-width: $boxed-width;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
}

.top-navigation.boxed-layout #wrapper,
.boxed-layout #wrapper.top-navigation {
  max-width: 1300px !important;
}

.block {
  display: block;
}

.clear {
  display: block;
  overflow: hidden;
}

a {
  cursor: pointer;
}

a:hover, a:focus {
  text-decoration: none;
}

.border-bottom {
  border-bottom: 1px solid $border-color !important;
}

.font-bold {
  font-weight: 600;
}

.font-normal {
  font-weight: 400;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-italic {
  font-style: italic;
}

.b-r {
  border-right: 1px solid $border-color;
}

.hr-line-dashed {
  border-top: 1px dashed $border-color;
  color: #fff;
  background-color: #fff;
  height: 1px;
  margin: 20px 0;
}

.hr-line-solid {
  border-bottom: 1px solid $border-color;
  background-color: rgba(0, 0, 0, 0);
  border-style: solid !important;
  margin-top: 15px;
  margin-bottom: 15px;
}

video {
  width: 100% !important;
  height: auto !important;
}

/* GALLERY */
.gallery > .row > div {
  margin-bottom: 15px;
}

.fancybox img {
  margin-bottom: 5px;
  /* Only for demo */
  width: 24%;
}

/* Summernote text editor  */
.note-editor {
  height: auto !important;
}

.note-editor.fullscreen {
  z-index: 2050;
}
.note-editor.note-frame.fullscreen { z-index: 2020; }

.note-editor.note-frame .note-editing-area .note-editable {
  color: $text-color;
  padding: 15px;
}

.note-editor.note-frame {
  border: none;
}

.note-editor.panel {
  margin-bottom: 0;
}

/* MODAL */
.modal-content {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  outline: 0 none;
  position: relative;
}

.modal-dialog {
  z-index: 2200;
}

.inmodal .modal-body {
  background: #fff;
}

.inmodal .modal-header {
  padding: 30px 15px;
  text-align: center;
}

.animated.modal.fade .modal-dialog {
  -webkit-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.detail-modal {
  max-height: 500px;
  overflow: scroll;
}

.inmodal .modal-title {
  font-size: 26px;
}

.inmodal .modal-icon {
  font-size: 84px;
  color: #e7eaec;
}

.modal-footer {
  margin-top: 0;
}

/* WRAPPERS */

#wrapper {
  width: 100%;
  overflow-x: hidden;
}

.wrapper {
  padding: 0 20px;
}

.wrapper-content {
  padding: 20px 10px 40px;
}

.margin-horizontal {
  margin-left: 5px;
  margin-right: 5px;
}

#page-wrapper {
  padding: 0 15px;
  min-height: 568px;
  position: relative !important;
}

@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 240px;
    min-height: 2002px;
  }
}

.title-action {
  text-align: right;
  padding-top: 30px;
}

.ibox-content h1, .ibox-content h2, .ibox-content h3, .ibox-content h4, .ibox-content h5,
.ibox-title h1, .ibox-title h2, .ibox-title h3, .ibox-title h4, .ibox-title h5 {
  margin-top: 5px;
}

ul.unstyled, ol.unstyled {
  list-style: none outside none;
  margin-left: 0;
}

.big-icon {
  font-size: 160px !important;
  color: #e7eaec;
}

.footer.fixed_full {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 20px;
  background: white;
  border-top: 1px solid $border-color;
}

.footer.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 20px;
  background: white;
  border-top: 1px solid $border-color;
  margin-left: $sidebar-width;
}

body.mini-navbar .footer.fixed,
body.body-small.mini-navbar .footer.fixed {
  margin: 0 0 0 70px;
}

body.mini-navbar.canvas-menu .footer.fixed,
body.canvas-menu .footer.fixed {
  margin: 0 !important;
}

body.fixed-sidebar.body-small.mini-navbar .footer.fixed {
  margin: 0 0 0 $sidebar-width;
}

body.body-small .footer.fixed {
  margin-left: 0;
}

/* PANELS */

.page-heading {
  border-top: 0;
  padding: 0 10px 20px 10px;
}

.panel-heading h1, .panel-heading h2 {
  margin-bottom: 5px;
}

.panel .nav-link:hover {
  background-color: #a6cc38;
}

/* TABLES */

.table-bordered {
  border: 1px solid #e7eaec;
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  background-color: #fff;
  border-bottom-width: 1px;
}

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 1px solid #e7eaec;
}


.table > thead {
  border: 0px !important;
}

.table > thead > tr > th {
  border-bottom: 0px solid #e7eaec;
  vertical-align: bottom;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  border-top: 1px solid $border-color;
  line-height: 1.42857;
  padding: 8px;
  vertical-align: middle;
}


.table > thead > tr > th, .table > thead > tr > td {
  border-top: 0px solid $border-color;
  line-height: 1.42857;
  padding: 8px;
  vertical-align: top;
}


/* PANELS */

.panel.blank-panel {
  background: none;
  margin: 0;
}

.blank-panel .panel-heading {
  padding-bottom: 0;
}

.nav-tabs > li > a {
  color: #a7b1c2;
  font-weight: 600;
  padding: 10px 20px 10px 25px;
}

.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
  background-color: #e6e6e6;
  color: $text-color;
}

.ui-tab .tab-content {
  padding: 20px 0;
}

/* GLOBAL  */

.no-padding {
  padding: 0 !important;
}

.no-borders {
  border: none !important;
}

.no-margins {
  margin: 0 !important;
}

.no-top-border {
  border-top: 0 !important;
}

.ibox-content.text-box {
  padding-bottom: 0;
  padding-top: 15px;
}

.border-left-right {
  border-left: 1px solid #e7eaec;
  border-right: 1px solid #e7eaec;
}

.border-top-bottom {
  border-top: 1px solid #e7eaec;
  border-bottom: 1px solid #e7eaec;
}

.border-left {
  border-left: 1px solid #e7eaec;
}

.border-right {
  border-right: 1px solid #e7eaec;
}

.border-top {
  border-top: 1px solid #e7eaec;
}

.border-bottom {
  border-bottom: 1px solid #e7eaec;
}

.border-size-sm {
  border-width: 3px;
}

.border-size-md {
  border-width: 6px;
}

.border-size-lg {
  border-width: 9px;
}

.border-size-xl {
  border-width: 12px;
}

.full-width {
  width: 100% !important;
}

.link-block {
  font-size: 12px;
  padding: 10px;
}

.nav.navbar-top-links .link-block a {
  font-size: 12px;
}

.link-block a {
  font-size: 10px;
  color: inherit;

}

body.mini-navbar .branding {
  display: none;
}

img.circle-border {
  border: 6px solid #fff;
  border-radius: 50%;
}

.branding {
  float: left;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 17px 20px;
  text-align: center;
}

.login-panel {
  margin-top: 25%;
}

.icons-box h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.icons-box .infont a i {
  font-size: 25px;
  display: block;
  color: $text-color;
}

.icons-box .infont a {
  color: #e6e6e6;
}

.icons-box .infont a {
  padding: 10px;

  margin: 1px;
  display: block;
}

.ui-draggable .ibox-title {
  cursor: move;
}

.breadcrumb {
  background-color: #fff;
  padding: 0;
  margin-bottom: 0;
}

.breadcrumb > li a {
  color: inherit;
}

.breadcrumb > .active {
  color: inherit;
}

code {
  background-color: #fff;
  border-radius: 4px;
  color: #012638;
  font-size: 90%;
  padding: 2px 4px;
  white-space: nowrap;
}

.lined {
  line-height: 32px;
  display: inline-block;
}

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}

.ibox.collapsed .ibox-content {
  display: none;
}

.ibox.collapsed .fa.fa-chevron-up:before {
  content: "\f078";
}

.ibox.collapsed .fa.fa-chevron-down:before {
  content: "\f077";
}

.ibox:after, .ibox:before {
  display: table;
}

.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: $ibox-title-bg;
  border-color: $border-color;
  border-image: none;
  border-style: solid solid none;
  border-width: 2px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 15px 15px 7px;
  min-height: 48px;
}

.ibox-content {
  background-color: $ibox-content-bg;
  color: inherit;
  padding: 15px 20px 20px 20px;

  border-color: $border-color;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}

.ibox-footer {
  color: inherit;
  border-top: 1px solid $border-color;
  font-size: 90%;
  background: #fff;
  padding: 10px 15px;
}

table.table-mail tr td {
  padding: 12px;
}

.table-mail .check-mail {
  padding-left: 20px;
}

.table-mail .mail-date {
  padding-right: 20px;
}

.star-mail, .check-mail {
  width: 40px;
}

.unread td a, .unread td {
  font-weight: 600;
  color: inherit;
}

.read td a, .read td {
  font-weight: normal;
  color: inherit;
}

.unread td {
  background-color: #e7eaec;
}

.ibox-content {
  clear: both;
}

.ibox-heading {
  background-color: #e7eaec;
  border-bottom: none;
}

.ibox-heading h3 {
  font-weight: 200;
  font-size: 24px;
}

.ibox-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  float: left;
}

.ibox-title .label {
  float: left;
  margin-left: 4px;
}

.ibox-tools {
  display: block;
  float: none;
  margin-top: 0;
  position: relative;
  padding: 0;
  text-align: right;
}

.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: #e6e6e6;
}

.ibox-tools a.btn-primary {
  color: #fff;
}

.ibox-tools .dropdown-menu > li > a {
  padding: 4px 10px;
  font-size: 12px;
}

.ibox .ibox-tools.open > .dropdown-menu {
  left: auto;
  right: 0;
}

/* BACKGROUNDS */


.gray-bg, .bg-muted {
  background-color: $background-gray;
}

.white-bg {
  background-color: #fff;
}

.blue-bg, .bg-success {
  background-color: $success-color;
  color: #fff;
}

.bg-primary {
  color: #fff;
}

.lazur-bg, .bg-info {
  background-color: $info-color;
  color: #fff;
}

.yellow-bg, .bg-warning {
  background-color: $warning-color;
  color: #fff;
}

.red-bg, .bg-danger {
  background-color: $danger-color;
  color: #fff;
}

.black-bg {
  background-color: #262626;
}


.panel-success {
  border-color: $success-color;
}

.panel-success > .panel-heading {
  background-color: $success-color;
  border-color: $success-color;
  color: #fff;
}

.panel-info {
  border-color: $info-color;
}

.panel-info > .panel-heading {
  background-color: $info-color;
  border-color: $info-color;
  color: #fff;
}

.panel-warning {
  border-color: $warning-color;
}

.panel-warning > .panel-heading {
  background-color: $warning-color;
  border-color: $warning-color;
  color: #fff;
}

.panel-danger {
  border-color: $danger-color;
}

.panel-danger > .panel-heading {
  background-color: $danger-color;
  border-color: $danger-color;
  color: #fff;
}

.progress-small, .progress-small .progress-bar {
  height: 10px;
}

.progress-small, .progress-mini {
  margin-top: 5px;
}

.progress-mini, .progress-mini .progress-bar {
  height: 5px;
  margin-bottom: 0;
}

.progress-bar-success {
  background-color: $success-color
}

.progress-bar-info {
  background-color: $info-color;
}

.progress-bar-warning {
  background-color: $warning-color;
}

.progress-bar-danger {
  background-color: $danger-color;
}

.panel-title {
  font-size: inherit;
}

.jumbotron {
  border-radius: 6px;
  padding: 40px;
}

.jumbotron h1 {
  margin-top: 0;
}

/* COLORS */

.text-primary {
  color: inherit;
}

.text-muted {
  color: #e6e6e6;
}

.text-white {
  color: #fff;
}

.simple_tag {
  background-color: #f3f3f4;
  border: 1px solid #e7eaec;
  border-radius: 2px;
  color: inherit;
  font-size: 10px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 5px 12px;
  display: inline-block;
}

.img-shadow {
  -webkit-box-shadow: 0 0 3px 0 rgba(145, 145, 145, 1);
  -moz-box-shadow: 0 0 3px 0 rgba(145, 145, 145, 1);
  box-shadow: 0 0 3px 0 rgba(145, 145, 145, 1);
}

/* For handle diferent bg color in AngularJS version */
.dashboards\.dashboard_2 nav.navbar,
.dashboards\.dashboard_3 nav.navbar,
.mailbox\.inbox nav.navbar,
.mailbox\.email_view nav.navbar,
.mailbox\.email_compose nav.navbar,
.dashboards\.dashboard_4_1 nav.navbar,
.metrics nav.navbar, .metrics\.index nav.navbar,
.dashboards\.dashboard_5 nav.navbar {
  background: #fff;
}

/* For handle diferent bg color in MVC version */
.Dashboard_2 .navbar.navbar-static-top,
.Dashboard_3 .navbar.navbar-static-top,
.Dashboard_4_1 .navbar.navbar-static-top,
.ComposeEmail .navbar.navbar-static-top,
.EmailView .navbar.navbar-static-top,
.Inbox .navbar.navbar-static-top,
.Metrics .navbar.navbar-static-top,
.Dashboard_5 .navbar.navbar-static-top {
  background: #fff;
}

a.close-canvas-menu {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 1011;
  color: #a7b1c2;
}

a.close-canvas-menu:hover {
  color: #fff;
}

.close-canvas-menu {
  display: none;
}

.canvas-menu .close-canvas-menu {
  display: block
}

.light-navbar .navbar.navbar-static-top {
  background-color: #fff;
}

/* FULL HEIGHT */

.full-height {
  height: 100%;
}

.fh-breadcrumb {
  height: calc(100% - 196px);
  margin: 0 -15px;
  position: relative
}

.fh-no-breadcrumb {
  height: calc(100% - 99px);
  margin: 0 -15px;
  position: relative
}

.fh-column {
  background: #fff;
  height: 100%;
  width: 240px;
  float: left
}

.modal-backdrop {
  z-index: 2040 !important;
}

.modal {
  z-index: 2050 !important;
}

.spiner-example {
  height: 200px;
  padding-top: 70px;
}

/* MARGINS & PADDINGS */

.p-xxs {
  padding: 5px;
}

.p-xs {
  padding: 10px;
}

.p-sm {
  padding: 15px;
}

.p-m {
  padding: 20px;
}

.p-md {
  padding: 25px;
}

.p-lg {
  padding: 30px;
}

.p-xl {
  padding: 40px;
}

.p-w-xs {
  padding: 0 10px;
}

.p-w-sm {
  padding: 0 15px;

}

.p-w-m {
  padding: 0 20px;

}

.p-w-md {
  padding: 0 25px;

}

.p-w-lg {
  padding: 0 30px;

}

.p-w-xl {
  padding: 0 40px;

}
.p-h-xs {
  padding: 10px 0;
}

.p-h-sm {
  padding: 15px 0;

}

.p-h-m {
  padding: 20px 0;

}

.p-h-md {
  padding: 25px 0;

}

.p-h-lg {
  padding: 30px 0;

}

.p-h-xl {
  padding: 40px 0;

}


.m-xxs {
  margin: 2px 4px;
}

.m {
  margin: 15px;
}

.m-xs {
  margin: 5px;
}

.m-sm {
  margin: 10px;
}

.m {
  margin: 15px;
}

.m-md {
  margin: 20px;
}

.m-lg {
  margin: 30px;
}

.m-xl {
  margin: 50px;
}

.m-n {
  margin: 0 !important;
}

.m-l-none {
  margin-left: 0;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l {
  margin-left: 15px;
}

.m-l-md {
  margin-left: 20px;
}

.m-l-lg {
  margin-left: 30px;
}

.m-l-xl {
  margin-left: 40px;
}

.m-l-n-xxs {
  margin-left: -1px;
}

.m-l-n-xs {
  margin-left: -5px;
}

.m-l-n-sm {
  margin-left: -10px;
}

.m-l-n {
  margin-left: -15px;
}

.m-l-n-md {
  margin-left: -20px;
}

.m-l-n-lg {
  margin-left: -30px;
}

.m-l-n-xl {
  margin-left: -40px;
}

.m-t-none {
  margin-top: 0;
}

.m-t-xxs {
  margin-top: 1px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t {
  margin-top: 15px;
}

.m-t-md {
  margin-top: 20px;
}

.m-t-lg {
  margin-top: 30px;
}

.m-t-xl {
  margin-top: 40px;
}

.m-t-n-xxs {
  margin-top: -1px;
}

.m-t-n-xs {
  margin-top: -5px;
}

.m-t-n-sm {
  margin-top: -10px;
}

.m-t-n {
  margin-top: -15px;
}

.m-t-n-md {
  margin-top: -20px;
}

.m-t-n-lg {
  margin-top: -30px;
}

.m-t-n-xl {
  margin-top: -40px;
}

.m-r-none {
  margin-right: 0;
}

.m-r-xxs {
  margin-right: 1px;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r {
  margin-right: 15px;
}

.m-r-md {
  margin-right: 20px;
}

.m-r-lg {
  margin-right: 30px;
}

.m-r-xl {
  margin-right: 40px;
}

.m-r-n-xxs {
  margin-right: -1px;
}

.m-r-n-xs {
  margin-right: -5px;
}

.m-r-n-sm {
  margin-right: -10px;
}

.m-r-n {
  margin-right: -15px;
}

.m-r-n-md {
  margin-right: -20px;
}

.m-r-n-lg {
  margin-right: -30px;
}

.m-r-n-xl {
  margin-right: -40px;
}

.m-b-none {
  margin-bottom: 0;
}

.m-b-xxs {
  margin-bottom: 1px;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b {
  margin-bottom: 15px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-b-lg {
  margin-bottom: 30px;
}

.m-b-xl {
  margin-bottom: 40px;
}

.m-b-n-xxs {
  margin-bottom: -1px;
}

.m-b-n-xs {
  margin-bottom: -5px;
}

.m-b-n-sm {
  margin-bottom: -10px;
}

.m-b-n {
  margin-bottom: -15px;
}

.m-b-n-md {
  margin-bottom: -20px;
}

.m-b-n-lg {
  margin-bottom: -30px;
}

.m-b-n-xl {
  margin-bottom: -40px;
}

.space-15 {
  margin: 15px 0;
}

.space-20 {
  margin: 20px 0;
}

.space-25 {
  margin: 25px 0;
}

.space-30 {
  margin: 30px 0;
}


/* IMAGES SIZE */

.img-sm {
  width: 32px;
  height: 32px;
}

.img-md {
  width: 64px;
  height: 64px;
}

.img-lg {
  width: 96px;
  height: 96px;
}

.b-r-xs {
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.b-r-sm {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.b-r-md {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.b-r-lg {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}

.b-r-xl {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

.fullscreen-ibox-mode .animated {
  animation: none;
}

body.fullscreen-ibox-mode {
  overflow-y: hidden;
}

.ibox.fullscreen {
  z-index: 2030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin-bottom: 0;
}

.ibox.fullscreen .collapse-link {
  display: none;
}

.ibox.fullscreen .ibox-content {
  min-height: calc(100% - 48px);
}

/* Show profile dropdown on fixed sidebar */
body.mini-navbar.fixed-sidebar .profile-element, .block {
  display: block !important;
}

body.mini-navbar.fixed-sidebar .nav-header {
  padding: 33px 25px;
}

body.mini-navbar.fixed-sidebar .logo-element {
  display: none;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

small {
  color: rgba(0, 0, 0, 0.5);
}

h1, h2, h3, h4, h5, h6, h2 span {
  font-weight: 100 !important;
}

h3 {
  font-size: 16px !important;
}

.link {
  color: #012638 !important;
}

.link:hover {
  color: #a6cc38 !important;
}

.simple_link {
  border-color: #012638 !important;
  color: #012638 !important;
  font-size: 12px;
  line-height: 1 !important;
  padding: 2px 0.5rem !important;
  margin-left: 10px;
}

.reverse-button {
  background-color: #fff !important;
  color: #012638 !important;
  border: 1px solid #012638 !important;
}

.reverse-button:hover {
  background-color: #012638 !important;
  color: #fff !important;
  border: #fff !important;
}

label {
  color: #707E8A;
}

.small-circle {
  display: inline-block;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  font-size: 16px;
}

.large-circle {
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.account-circle {
  background-color: #F5A623;
}

.default-circle {
  background-color: #50E3C2;
}

.SingleDatePicker_1, .SingleDatePickerInput {
  width: 100%;
}

.SingleDatePickerInput, .DateInput_input {
  display: block;
  width: 100%;
  padding: 6px 0px 6px 0px !important;
  font-size: 1rem !important;
  line-height: 0;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}

.SingleDatePicker_picker {
  z-index: 10 !important;
}

.DateInput_input {
  padding: 0px 12px 0px 5px !important;
  line-height: 22px !important;
}

.DateInput_1 {
  width: 80% !important;
}

.SingleDatePickerInput_calendarIcon {
  padding: 0px 5px !important;
  margin: 0px;
  vertical-align: inherit !important;
}

.DateInput_input__focused {
  border-bottom: none !important;
}

.DateInput_fang {
  display: none;
}

.input-group-addon:not(:last-child) {
  border-right: 0;
}

.input-group-addon {
  padding: .0rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #464a4c;
  text-align: center;
  background-color: #eceeef;
  border: 1px solid rgba(0,0,0,.15);
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.input-group-addon, .input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
}

.input-group .form-control, .input-group-addon, .input-group-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pagination {
    display: inline-block;
    border-radius: .25rem;
    justify-content: center;
}

.pagination .previous:first-child a {
    margin-left: 0;
    border-bottom-left-radius: .25rem;
    border-top-left-radius: .25rem;
}

.pagination .next:last-child a {
  border-bottom-right-radius: .25rem;
  border-top-right-radius: .25rem;
}

.pagination .disabled a {
  color: #636c72;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.pagination li a {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #012638;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination .active a {
  z-index: 2;
  color: #fff;
  background-color: #012638;
  border-color: #012638;
}

.no-margin {
  margin: 0 !important;
}

.clear-all {
  text-align: center !important;
  color: #012638 !important;
}

.pagination a:hover:not(.active) {background-color: #ddd;}

img {
  max-width: 100%;
}

.navbar-brand img {
  height: 40px;
}

.company_name {
  margin-top: 25%;
}

.dropdown-menu {
  left: -125px !important;
}

.dropdown-icon {
  margin-right: 25px;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width : 990px){
  .nav-item {
    border-bottom: 1px solid grey;
  }

  .navbar-collapse {
    position: absolute;
    top: 50px;
    z-index: 99;
    background: white;
    width: 90%;
  }

  .nav-link {
    color: #000 !important;
  }
}

.modal-footer{
  flex: 1;
  display: inline !important;
  border-top: none !important;
}

.modal-footer .reverse-button {
  margin-bottom: 10px;
}

.modal-footer .btn-secondary {
  margin-bottom: 10px;
  margin-right: 10px;
  float: right;
}
#logo{
  background: white;
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
 }

.hoverlink{
  background-color: purple;
}

.margin-5 {
  margin-top: 5px;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.not-selected {
  background-color: #fff !important;
  color: black !important;
}

.form-check-input {
  width: 15px;
  height: 15px;
  margin-top: 2px !important;
  float: left;
}

.form-check-label {
  padding-left: 0px !important;
}

.col-sm-3 small {
  line-height: 3;
}

.description {
  color: #a7b1c2;
  font-size: 11px;
}

.connectedSortable {
  border: 1px solid #A0A0A0;
  padding: 20px;
}

.connectedSortable li {
  list-style-type: none;
  display: inline-block;
  margin-right: 50px;
}

.container-image {
  position: relative;
  width: 100%;
}

.container-image .fa {
  position: absolute;
  top: 15px;
  left: 90%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: #000;
  cursor: pointer;
  padding: 5px;
  background-color: #fff;
}
